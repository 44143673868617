import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as solidHeart, faHeart as regularHeart } from '@fortawesome/free-solid-svg-icons';
import logo from '../img/fujm.png';
import Popup from './Popup';
import { database } from '../firebaseConfig'; // Import database from firebaseConfig
import { ref, onValue, update } from 'firebase/database'; // Import update from Firebase
import './Wall.css';
import DOMPurify from 'dompurify'; // Import dompurify to sanitize HTML
import { useNavigate, useLocation } from 'react-router-dom';

function Wall() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state for fetching data
  const [selectedImage, setSelectedImage] = useState(null); // State for the selected image
  const [sortBy, setSortBy] = useState('date'); // Default sorting by date
  const [searchTerm, setSearchTerm] = useState(''); // Add this new state
  const navigate = useNavigate();
  const location = useLocation();
  const [userEmail, setUserEmail] = React.useState(null);
  const [userEmailKey, setUserEmailKey] = React.useState(null);

  // Memoize the sortPosts function with useCallback
  const sortPosts = useCallback((postList) => {
    if (sortBy === 'date') {
      postList.sort((a, b) => b.timestamp - a.timestamp); // Sort by posting date
    } else if (sortBy === 'lineCount') {
      postList.sort((a, b) => b.text.split('\n').length - a.text.split('\n').length); // Sort by line count
    }

    setPosts(postList); // Update state with sorted posts
  }, [sortBy]); // Dependency array includes sortBy so it will recalculate when sortBy changes

  // Fetch posts from Firebase
  useEffect(() => {
    const postsRef = ref(database, 'posts'); // Reference to 'posts' node in Firebase
    document.body.classList.add('wall-active');
    onValue(postsRef, (snapshot) => {
      const data = snapshot.val();
      const postList = [];
      for (let id in data) {
        postList.push({ id, ...data[id] });
      }

      // Sort posts based on selected sort option
      sortPosts(postList);

      setLoading(false); // Set loading to false once data is fetched
    });
  }, [sortBy, sortPosts]); // Add sortPosts to the dependency array

  React.useEffect(() => {
    if (location.state) {
      const { email, userEmailKey } = location.state;
      setUserEmail(email);
      setUserEmailKey(userEmailKey);
    }
  }, [location.state]);

  const handleLogout = () => {
    setUserEmail(null);
    setUserEmailKey(null);
    navigate('/');
  };

  const handleSignInSignUp = () => {
    navigate('/login');
  };

  const handleLikeToggle = (id) => {
    setPosts(prevPosts =>
      prevPosts.map(post => {
        if (post.id === id) {
          const currentLikeCount = post.likes || 0;
          const newLikeCount = post.liked ? currentLikeCount - 1 : currentLikeCount + 1;

          const updatedPost = {
            ...post,
            liked: !post.liked,
            likes: newLikeCount
          };

          if (!isNaN(newLikeCount)) {
            const postRef = ref(database, `posts/${id}`);
            update(postRef, { likes: newLikeCount, liked: !post.liked });
          } else {
            console.error("Invalid like count value:", newLikeCount);
          }

          return updatedPost;
        }
        return post;
      })
    );
  };

  const handleImageClick = (image) => {
    setSelectedImage(image); // Set the selected image
  };

  const handleOverlayClose = () => {
    setSelectedImage(null); // Close the overlay
  };

  // Add this new function after other handlers
  const filteredPosts = useCallback(() => {
    return posts.filter(post =>
      post.text.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [posts, searchTerm]);

  if (loading) {
    return <div>Loading...</div>; // Display loading text while fetching data
  }

  return (
    <div className="job-list-page">
      <div className="navbar">
        <span
          className="navbar-title"
          onClick={() =>
            navigate("/", {
              state: { email: userEmail, userEmailKey: userEmailKey },
            })
          }
        >
          <img src="/m1.svg" alt="Job Board" width="34" height="34" />
          <b style={{ fontFamily: 'Georgia' }}>Wall</b>
        </span>

        <div className="search-container">
          <input
            type="text"
            placeholder="Search posts..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-input"
          />
          <div className="nav-buttons">
            <button className="space-btn" onClick={() => navigate("/")}>
              Home
            </button>
            <button
              className="space-btn signin-signup-button"
              onClick={handleSignInSignUp}
            >
              Sign In / Sign Up
            </button>
          </div>
        </div>
        <div className={`loading-bar`}></div>
      </div>

      <div className="WallContainer">
        <div className="WallGrid">
          <div className="content-column">
            <div className="popup-container">
              <Popup />
            </div>
            
            <div className="card-container">
              {filteredPosts().map((post) => (
                <div key={post.id} className="tweet-card">
                  <div className="card-content">
                    {/* Render text with sanitized HTML */}
                    <p className="tweet-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.text) }}></p>

                    {post.images.image1 && (
                      <img
                        src={post.images.image1}
                        alt="PostImage1"
                        className="tweet-image"
                        onClick={() => handleImageClick(post.images.image1)} // Click to open image in overlay
                      />
                    )}
                    {post.images.image2 && (
                      <img
                        src={post.images.image2}
                        alt="PostImage2"
                        className="tweet-image"
                        onClick={() => handleImageClick(post.images.image2)} // Click to open image in overlay
                      />
                    )}
                    <div className="likes-section">
                      <button className="like-button" onClick={() => handleLikeToggle(post.id)}>
                        <FontAwesomeIcon
                          icon={post.liked ? solidHeart : regularHeart}
                          className={`icon ${post.liked ? 'liked' : ''}`}
                        />
                      </button>
                      <span className="likes">{post.likes || 0} {post.likes === 1 ? 'Like' : 'Likes'}</span>
                    </div>
                    <p className="timestamp">
                      Posted on: {new Date(post.timestamp).toLocaleString()}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Image Overlay Modal */}
        {selectedImage && (
          <div className="image-overlay" onClick={handleOverlayClose}>
            <img src={selectedImage} alt="Selected" className="overlay-image" />
          </div>
        )}
      </div>
    </div>
  );
}

export default Wall;
