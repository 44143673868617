import React, { useState, useRef } from 'react';
import './Popup.css';
import { ref, set } from "firebase/database";
import { database } from "../firebaseConfig";
import CompanySelector from './CompanySelector';

const Popup = () => {
  const [inputText, setInputText] = useState('');
  const [jobLink, setJobLink] = useState('');
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [processedOutput, setProcessedOutput] = useState(null);

  const editableRef = useRef(null);

  const togglePopup = () => {
    setIsOpen(!isOpen);
    setInputText('');
    setJobLink('');
    setShowPlaceholder(true);
    setImage1(null);
    setImage2(null);
    setSelectedCompany(null);
  };

  const handleFocus = () => setShowPlaceholder(false);
  const handleBlur = () => setShowPlaceholder(inputText.length === 0);

  const handleInput = async (e) => {
    const text = e.target.innerHTML;
    setInputText(text);
    
    if (text.length > 50) {
      try {
        console.log('Sending job description to backend:', text);
        
        const response = await fetch('/.netlify/functions/process-job-description', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ jobDescription: text })
        });
        
        if (!response.ok) {
          throw new Error(`Server error: ${response.status} ${response.statusText}`);
        }
        
        const responseData = await response.json();
        console.log('Processed data received:', responseData);
        
        if (responseData.data) {
          const analysis = responseData.data.analysis;
          if (analysis) {
            setProcessedOutput({
              success: true,
              data: analysis,
              originalText: responseData.data.originalText
            });
          } else {
            throw new Error('Analysis is undefined in the response');
          }
        } else {
          throw new Error('Invalid response structure from backend');
        }
      } catch (error) {
        console.error('Error processing job description:', error);
        setProcessedOutput({
          success: false,
          error: error.message
        });
      }
    }
  };

  const handlePostTweet = () => {
    if (!selectedCompany) {
      alert("Please select a company before posting.");
      return;
    }

    const postId = `post_${Date.now()}`;
    const postData = {
      text: inputText,
      jobLink,
      timestamp: Date.now(),
      company: selectedCompany ? { name: selectedCompany.name, logo: selectedCompany.logo } : null,
      likes: 0,
    };

    set(ref(database, 'posts/' + postId), postData)
      .then(() => {
        console.log('Post uploaded successfully!');
        togglePopup();
      })
      .catch((error) => console.error('Error uploading post:', error));
  };

  return (
    <div className="PopupContainer">
      <button onClick={togglePopup} className="popup-btn">
        <div className="popup-btn-content">
          <svg viewBox="0 0 24 24" className="popup-icon">
            <path d="M12 4C12.5523 4 13 4.44772 13 5V11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H13V19C13 19.5523 12.5523 20 12 20C11.4477 20 11 19.5523 11 19V13H5C4.44772 13 4 12.5523 4 12C4 11.4477 4.44772 11 5 11H11V5C11 4.44772 11.4477 4 12 4Z" />
          </svg>
        </div>
      </button>
      {isOpen && (
        <div className="popup-overlay" onClick={togglePopup}>
          <div className="popup-inner-container" onClick={(e) => e.stopPropagation()}>
            <div className="popup-header">
              <h2>Share Job Post</h2>
              <button onClick={togglePopup} className="close-btn">
                <svg viewBox="0 0 24 24" width="20" height="20">
                  <path d="M6.707 6.293a1 1 0 011.414 0L12 10.172l3.879-3.879a1 1 0 111.414 1.414L13.414 12l3.879 3.879a1 1 0 01-1.414 1.414L12 13.414l-3.879 3.879a1 1 0 01-1.414-1.414L10.586 12 6.707 8.121a1 1 0 010-1.414z" />
                </svg>
              </button>
            </div>


            <input type="url" placeholder="Enter job posting URL" value={jobLink} onChange={(e) => setJobLink(e.target.value)} className="job-input" />
            
            <div className="tweet-area">
              {showPlaceholder && <span className="placeholder placeholder-box">Share the job description...</span>}
              <div className="input editable" contentEditable spellCheck="false" onFocus={handleFocus} onBlur={handleBlur} onInput={handleInput} ref={editableRef} />
              </div>
              <div>
              {processedOutput && (
                <div className="processed-output processed-output-box">
                  <h3>Processed Output:</h3>
                  <pre>{JSON.stringify(processedOutput, null, 2)}</pre>
                </div>
              )}
            </div>

            <button className="post-btn" onClick={handlePostTweet} disabled={!inputText || !selectedCompany || !jobLink}>Post</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Popup;