import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { database, ref, set, get } from '../firebaseConfig';
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth"; // Updated import
import mammoth from 'mammoth';
import * as pdfjsLib from 'pdfjs-dist/webpack';
import './UserDashboard.css';
import axios from 'axios';

const UserDashboard = () => {
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [fileText, setFileText] = useState("");
  const [error, setError] = useState("");
  const [extractedProfile, setExtractedProfile] = useState({});
  const [loading, setLoading] = useState(false);
  const [showFullText, setShowFullText] = useState(false);
  const [showFullPreview, setShowFullPreview] = useState(false);
  const [previewText, setPreviewText] = useState("");
  const [profileForm, setProfileForm] = useState({
    "First Name": "",
    "Middle Name": "",
    "Last Name": "",
    "email": "",
    "phone": "",
    "address": {
      "street": "",
      "city": "",
      "state": "",
      "zipCode": "",
      "country": ""
    },
    "linkedin": "",
    "website": "",
    "summary": "",
    "education": [
      {
        "degree": "",
        "fieldOfStudy": "",
        "institution": "",
        "location": "",
        "graduationYear": ""
      }
    ],
    "workExperience": [
      {
        "jobTitle": "",
        "company": "",
        "location": "",
        "startDate": "",
        "endDate": "",
        "responsibilities": [""]
      }
    ],
    "skills": [""],
    "certifications": [
      {
        "title": "",
        "issuingOrganization": "",
        "issueDate": "",
        "expirationDate": ""
      }
    ],
    "languages": [
      {
        "language": "",
        "proficiency": ""
      }
    ],
    "interests": [""]
  });
  const [userEmail, setUserEmail] = useState(""); // Store user email

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUserEmail(user.email);
        // Fetch existing profile data
        try {
          const safeEmail = user.email.replace(/[.#$\[\]]/g, '_');
          const profileRef = ref(database, `profiles/${safeEmail}`);
          const snapshot = await get(profileRef);
          
          if (snapshot.exists()) {
            const profileData = snapshot.val();
            setProfileForm(profileData);
            setExtractedProfile(profileData);
          }
        } catch (err) {
          setError("Error loading profile: " + err.message);
        }
      } else {
        navigate('/signin');
      }
    });
  }, [navigate]);

  const handleLogout = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      setError("Error logging out: " + error.message);
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setError("");
    setFileText("");
    setExtractedProfile({});
    setShowFullText(false);
    if (e.target.files[0]) {
      extractText(e.target.files[0]);
    }
  };

  const extractText = async (file) => {
    if (!file) {
      setError("Please select a file.");
      return;
    }

    const fileType = file.name.split(".").pop().toLowerCase();
    if (fileType === "pdf") {
      try {
        const fileReader = new FileReader();
        fileReader.onload = async function () {
          try {
            const typedArray = new Uint8Array(this.result);
            const pdf = await pdfjsLib.getDocument(typedArray).promise;
            let fullText = '';
            
            // Get all pages
            for (let i = 1; i <= pdf.numPages; i++) {
              const page = await pdf.getPage(i);
              const textContent = await page.getTextContent();
              const pageText = textContent.items.map((item) => item.str).join(" ");
              fullText += pageText + "\n\n";
            }
            
            setFileText(fullText);
            setPreviewText(fullText.slice(0, 300) + "..."); // Short preview
          } catch (err) {
            setError("Error extracting text from PDF.");
          }
        };
        fileReader.readAsArrayBuffer(file);
      } catch (err) {
        setError("Error reading PDF file.");
      }
    } else if (fileType === "docx") {
      try {
        const arrayBuffer = await file.arrayBuffer();
        const { value } = await mammoth.extractRawText({ arrayBuffer });
        setFileText(value);
        setPreviewText(value.slice(0, 300) + "..."); // Short preview
      } catch (err) {
        setError("Error extracting text from DOCX.");
      }
    } else {
      setError("Unsupported file type. Please upload a PDF or DOCX file.");
    }
  };

  const handleBuildProfile = async () => {
    if (!fileText) {
      setError("Upload file again.");
      return;
    }

    setLoading(true); // Start loading

    try {
      const response = await axios.post('https://fujm.org/.netlify/functions/profile-builder', {
        text: fileText,
      });

      const profileData = JSON.parse(response.data.profile);
      setExtractedProfile(profileData);
      setProfileForm(profileData); // Initialize form state
    } catch (err) {
      setError("Error building profile: " + err.message);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleFieldChange = (field, value) => {
    if (field === "address") {
      setProfileForm((prev) => ({
        ...prev,
        address: {
          ...prev.address,
          ...value,
        },
      }));
    } else {
      setProfileForm((prev) => ({
        ...prev,
        [field]: value,
      }));
    }
  };
  
  const addField = () => {
    const newKey = prompt("Enter the field name:");
    if (newKey) {
      setProfileForm({
        ...profileForm,
        [newKey]: "",
      });
    }
  };

  const removeField = (key) => {
    const { [key]: _, ...newProfileForm } = profileForm;
    setProfileForm(newProfileForm);
  };

  const handleArrayFieldChange = (key, index, subKey, value) => {
    setProfileForm((prev) => {
      const newArray = [...prev[key]];
      if (subKey !== null) {
        newArray[index][subKey] = value; // Update specific property
      } else {
        newArray[index] = value; // Update the string directly
      }
      return { ...prev, [key]: newArray };
    });
  };

  const addArrayField = (key) => {
    let newItem = {};
    if (key === "education") {
      newItem = {
        degree: "",
        fieldOfStudy: "",
        institution: "",
        location: "",
        graduationYear: ""
      };
    } else if (key === "workExperience") {
      newItem = {
        jobTitle: "",
        company: "",
        location: "",
        startDate: "",
        endDate: "",
        responsibilities: [""]
      };
    } else if (key === "skills") {
      newItem = ""; // Skills are strings
    }
   else if (key === "certifications") {
      newItem = {
          title: "",
          issuingOrganization: "",
          issueDate: "",
          expirationDate: ""
      };
  } else if (key === "projects") {
      newItem = {
          title: "",
          description: "",
          technologiesUsed: [""],
          link: ""
      };
  } else if (key === "languages") {
      newItem = {
          language: "",
          proficiency: ""
      };
  } else if (key === "interests") {
      newItem = ""; // Interests are strings
  }

    setProfileForm((prev) => ({
      ...prev,
      [key]: [...prev[key], newItem],
    }));
  };

  const removeArrayField = (key, index) => {
    setProfileForm((prev) => {
      const newArray = [...prev[key]];
      newArray.splice(index, 1);
      return { ...prev, [key]: newArray };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      try {
        const safeEmail = user.email.replace(/[.#$\[\]]/g, '_');
        const profileRef = ref(database, `profiles/${safeEmail}`);
        await set(profileRef, profileForm);
        setError(""); // Clear any existing errors
        alert("Profile saved successfully!"); // Add user feedback
        navigate('/profile', { state: { email: user.email } }); // Navigate to profile page
      } catch (err) {
        setError("Error updating profile: " + err.message);
      }
    } else {
      setError("User not authenticated. Please sign in.");
    }
  };

  const resetState = () => {
    setFile(null);
    setFileText("");
    setError("");
    setExtractedProfile({});
    setProfileForm({
      "First Name": "",
      "Middle Name": "",
      "Last Name": "",
      "email": "",
      "phone": "",
      "address": {
        "street": "",
        "city": "",
        "state": "",
        "zipCode": "",
        "country": ""
      },
      "linkedin": "",
      "website": "",
      "summary": "",
      "education": [
        {
          "degree": "",
          "fieldOfStudy": "",
          "institution": "",
          "location": "",
          "graduationYear": ""
        }
      ],
      "workExperience": [
        {
          "jobTitle": "",
          "company": "",
          "location": "",
          "startDate": "",
          "endDate": "",
          "responsibilities": [""]
        }
      ],
      "skills": [""],
      "certifications": [
        {
          "title": "",
          "issuingOrganization": "",
          "issueDate": "",
          "expirationDate": ""
        }
      ],

      "languages": [
        {
          "language": "",
          "proficiency": ""
        }
      ],
      "interests": [""] 
    });
    setShowFullText(false);
  };

  return (
    <div className="user-dashboard">
      <div className="navbar">
        <div className="navbar-left">
          <span className="navbar-title" onClick={() => navigate("/", { state: { email: userEmail } })}>
            <img src="/m1.svg" alt="Seeker Space" width="34" height="34" />
            <span className="title-text">Seeker Space</span>
          </span>
        </div>
        
        <div className="navbar-right">
          <div className="search-container">
            <input
              type="search"
              placeholder="Search..."
              className="search-input"
            />
          </div>
          
          <div className="nav-buttons">
            <button className="space-btn" onClick={() => navigate("/")}>
              Home
            </button>
            <button className="space-btn" onClick={() => navigate("/job-tracker")}>
              Job Tracker
            </button>
            <button className="space-btn" onClick={() => navigate("/history")}>
              History
            </button>
            <button className="space-btn" onClick={() => navigate("/fujm-wall")}>
              FUJM Wall
            </button>
            <button className="space-btn logout-button" onClick={handleLogout}>
              Logout
            </button>
          </div>
        </div>
        
        <div className={`loading-bar ${loading ? 'loading' : ''}`}></div>
      </div>

      <main className="dashboard-main">
        <section className="file-upload-section">
          {Object.keys(extractedProfile).length === 0 && (
            <>
              <div className="button-group">
                <button 
                  onClick={handleBuildProfile} 
                  className="space-btn" 
                  disabled={loading}
                >
                  Build Profile
                </button>
                <button 
                  onClick={resetState} 
                  className='space-btn'
                >
                  Reset
                </button>
              </div>

              <div className="file-upload-wrapper">
                <input 
                  className="file-upload-input" 
                  type="file" 
                  accept=".pdf, .docx" 
                  onChange={handleFileChange}
                  id="file-upload"
                />
                <label htmlFor="file-upload" className="file-upload-label">
                  <svg className="upload-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                    <polyline points="17 8 12 3 7 8" />
                    <line x1="12" y1="3" x2="12" y2="15" />
                  </svg>
                  <span>Upload Resume. Choose a file or drag it here</span>
                  <span className="file-types">.pdf, .docx</span>
                </label>
              </div>
            </>
          )}
          
          {fileText && (
            <div className="file-preview-container">
              <div className="preview-header">
                <h4>File Preview</h4>
                <button 
                  className="toggle-preview-btn"
                  onClick={() => setShowFullPreview(!showFullPreview)}
                >
                  {showFullPreview ? 'Show Less' : 'Show Full'}
                </button>
              </div>
              <div className={`file-preview-content ${showFullPreview ? 'full' : ''}`}>
                {showFullPreview ? fileText : previewText}
              </div>
            </div>
          )}

          {error && <p className="error-message">{error}</p>}
        </section>

        {Object.keys(extractedProfile).length > 0 && (
          <form onSubmit={handleSubmit} className="profile-form">
            <div className="profile-form-header">
              <h3>Edit Profile:</h3>
              <div className="file-upload-controls">
                <div className="file-upload-wrapper compact">
                  <input 
                    className="file-upload-input" 
                    type="file" 
                    accept=".pdf, .docx" 
                    onChange={handleFileChange}
                    id="file-upload"
                  />
                  <label htmlFor="file-upload" className="file-upload-label compact">
                    <svg className="upload-icon small" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                      <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                      <polyline points="17 8 12 3 7 8" />
                      <line x1="12" y1="3" x2="12" y2="15" />
                    </svg>
                    Upload Resume
                  </label>
                </div>
                <div className="button-group compact">
                  <button 
                    type="button"
                    onClick={handleBuildProfile} 
                    className="space-btn small" 
                    disabled={loading}
                  >
                    Build Profile
                  </button>
                  <button 
                    type="button"
                    onClick={resetState} 
                    className='space-btn small'
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
            
            {Object.entries(profileForm).map(([key, value]) => {
              if (Array.isArray(value)) {
                return (
                  <div key={key}>
                    <h4>{key}</h4>
                    {value.map((item, index) => (
                      <div key={index} className="array-item">
                        {typeof item === 'object' ? (
                          Object.entries(item).map(([subKey, subValue]) => (
                            <input
                              key={subKey}
                              type="text"
                              value={subValue}
                              placeholder={subKey}
                              onChange={(e) => handleArrayFieldChange(key, index, subKey, e.target.value)}
                            />
                          ))
                        ) : (
                          <input
                            type="text"
                            value={item}
                            onChange={(e) => handleArrayFieldChange(key, index, null, e.target.value)}
                          />
                        )}
                        <button className="custom-btn-rem btn-4-rem" type="button" onClick={() => removeArrayField(key, index)}>Remove</button>
                      </div>
                    ))}
                    <button className="custom-btn btn-4" type="button" onClick={() => addArrayField(key)}>Add {key.slice(0, -1)}</button>
                  </div>
                );
              }

              return (
                <div key={key}>
                  <label>{key}</label>
                  <input
                    type="text"
                    value={value}
                    onChange={(e) => handleFieldChange(key, e.target.value)}
                  />
                </div>
              );
            })}
            <button type="submit">Save Profile</button>
          </form>
        )}
      </main>
    </div>
  );
};

export default UserDashboard;
