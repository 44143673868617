import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";

import { Modal, Button } from "react-bootstrap";
import "./App.css";
import JobListPage from "./components/JobListPage";
import GooglePlacesAutocomplete from 'react-google-autocomplete';
import UploadFile from "./components/UploadFile";
import "bootstrap/dist/css/bootstrap.min.css";
import SignInSignUp from "./components/SignInSignUp";
import UserDashboard from "./components/UserDashboard";
import Wall from "./components/Wall";
import QuickSignInModal from "./components/QuickSignInModal";
import ProfilePage from "./components/ProfilePage";
import Squares from './components/Squares';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getDatabase, ref, get } from 'firebase/database';

const App = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [datePosted, setDatePosted] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [userEmail, setUserEmail] = useState(null); // State to store the user email
  const [userEmailKey, setuserEmailKey] = useState(null); // State to store the user email
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const [isQuickSignInOpen, setQuickSignInOpen] = useState(false);
  const [locationKeyPressHandled, setLocationKeyPressHandled] = useState(false);
  const toggleQuickSignIn = () => {
    setQuickSignInOpen((prevState) => !prevState);
  };
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserEmail(user.email);
        const userEmailKey = user.email.replace(/\./g, ',');
        setuserEmailKey(userEmailKey);
        setIsAuthenticated(true);
      } else {
        setUserEmail(null);
        setuserEmailKey(null);
        setIsAuthenticated(false);
      }
    });

    // Cleanup subscription
    return () => unsubscribe();
  }, []);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleLocationSelect = (place) => {
    if (place && place.formatted_address) {
      setSelectedLocation(place.formatted_address);

      console.log("Selected location:", place);
    } else {
      console.log("No valid location data returned.");
    }
  };

  const handleSearchRedirect = () => {
    navigate("/joblist", { state: { searchTerm, selectedLocation } });
  };
  const handleSearch = () => {
    console.log("Search function triggered.");
    const searchUrl = `/jobs?search=${encodeURIComponent(
      searchTerm
    )}&location=${encodeURIComponent(
      selectedLocation || ''
    )}&datePosted=${encodeURIComponent(datePosted)}`;

    if (userEmail && userEmailKey) {
      navigate(searchUrl, {
        state: { email: userEmail, userEmailKey },
      });
    } else {
      navigate(searchUrl);
    }
  };

  const handleLocationKeyPress = async (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const locationInput = document.querySelector(".location-input input");
  
      if (locationInput && locationInput.value) {
        try {
          const service = new window.google.maps.places.AutocompleteService();
          const predictions = await new Promise((resolve, reject) => {
            service.getPlacePredictions(
              {
                input: locationInput.value,
                types: ["(cities)"],
              },
              (predictions, status) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                  resolve(predictions);
                } else {
                  reject(status);
                }
              }
            );
          });
  
          if (predictions && predictions.length > 0) {
            setSelectedLocation(predictions[0].description);
            locationInput.value = predictions[0].description;
            setLocationKeyPressHandled(true); // Enable search keypress handling
          }
        } catch (error) {
          console.log("Error getting predictions:", error);
        }
      }
    }
  };

  const handleSearchKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearch();
    }
  };

  const handleLogout = () => {
    const auth = getAuth();
    auth.signOut().then(() => {
      setUserEmail(null);
      setuserEmailKey(null);
      setIsAuthenticated(false);
      navigate('/');
    }).catch((error) => {
      console.error('Error signing out:', error);
    });
  };

  const handleProfileClick = async () => {
    if (!userEmailKey) return;
    
    const db = getDatabase();
    const profileRef = ref(db, `users/${userEmailKey}/profile`);
    
    try {
      const snapshot = await get(profileRef);
      if (snapshot.exists()) {
        navigate("/profile");
      } else {
        navigate("/user-dashboard");
      }
    } catch (error) {
      console.error("Error checking profile:", error);
      navigate("/user-dashboard"); // Default to dashboard on error
    }
  };

  return (
    <div className="fujm-container" style={{ position: 'relative', minHeight: '100vh' }}>
      <Squares 
        speed={0.3} 
        squareSize={100}
        direction='diagonal'
        borderColor='#edeef0'
        hoverFillColor='#222'
        className="squares-background"
      />
      {/* Conditional rendering based on whether the user is logged in */}
      {userEmail ? (
        <div className="auth-section">
          {/* <button className="auth-link2" onClick={() => navigate("/fujm-wall")}>
            FUJM Wall
          </button> */}
          <button className="auth-link" onClick={handleProfileClick}>
            Profile
          </button>
          <button className="auth-link" onClick={handleLogout}>
            Logout
          </button>

        </div>
      ) : (
        <div className="auth-section">
          {/* <button className="auth-link2" onClick={() => navigate("/fujm-wall")}>
          FUJM Wall
          </button> */}
          {/* <button className="auth-link2" onClick={toggleQuickSignIn}>
          Quick Sign In
          </button> */}
          <button className="auth-link1" onClick={() => navigate("/login")}>
            Login / Sign Up
          </button>
        </div>
      )}

      {/* Quick SignIn Modal Start */}
      {isQuickSignInOpen && (
        <QuickSignInModal onClose={toggleQuickSignIn} />
      )}
      {/* Quick SignIn Modal Close */}

      <div className="navbar1">
        <div className="logo-holder logo-2">
          <h3>fujm</h3>
          <p>{userEmail ? userEmail : "for seekers by seeker"}</p>{" "}
          {/* Show userEmail if available, else default text */}{" "}
        </div>
      </div>

      {/* {new code start} */}
      {/* {new code end} */}

      <div className="search-bar-container">
        <input
          type="text"
          className="search-bar"
          placeholder="Enter Job Position"
          onKeyDown={handleSearchKeyPress}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <div className="filter-container">
  <GooglePlacesAutocomplete
    apiKey="AIzaSyBhOM8A9bFdMx6ixBc2FqoR-9oPge8hyEM"
    onPlaceSelected={handleLocationSelect}
    options={{
      types: ["(cities)"],
    }}
    placeholder="Type a location..."
    className="location-input"
    onKeyDown={locationKeyPressHandled ? handleSearchKeyPress : handleLocationKeyPress}
  />
</div>


      <div className="button-container">
        <button className="button search-button" onClick={handleSearch}>
          fujm Search
        </button>
      </div>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Upload Your Resume</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UploadFile />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCloseModal}>
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="made-with-love">
        Made with 
        <div className="heart-container">
          <svg 
            stroke="currentColor" 
            fill="none" 
            strokeWidth="2" 
            viewBox="0 0 24 24" 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            height="1em" 
            width="1em" 
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>
          </svg>
        </div>
        by
        <a 
          target="_blank" 
          rel="noopener noreferrer"
          className="author-link" 
          href="https://github.com/Negi97Mohit"
        >
          this guy
        </a>
      </div>
    </div>
  );
};

const MainApp = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/jobs" element={<JobListPage />} />
        <Route path="/login" element={<SignInSignUp />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/user-dashboard" element={<UserDashboard />} />
        {/* Add route for Job Wall page */}
        <Route path="/fujm-wall" element={<Wall />} />
      </Routes>
    </Router>
  );
};

export default MainApp;
